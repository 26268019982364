<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ headline }}
        </v-card-title>
        <v-card-text>{{ body }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'ErrorDialog',
    mixins: [],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      headline: {
        type: String,
        default: 'An error has occurred',
      },
      body: {
        type: String,
        default: 'An Unknown Error has Occurred.',
      },
    },
    data() {
      return {
        dialog: false,
      };
    },
    computed: {},
    watch: {
      value: function () {
        this.dialog = this.value;
      },
    },
    created() {},
    mounted() {},
    methods: {
      closeDialog() {
        this.dialog = false;
        this.$emit('input', false);
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
