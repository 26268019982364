const dateTimeHelpers = {
  methods: {
    // this method takes a string or date object, and returns something like "8/19/2019"
    displayDateFormat: function (dateValue, format) {
      if (dateValue) {
        var date;
        if (typeof dateValue === 'string') {
          var dateArray = [];

          if (dateValue.indexOf('T') > -1) {
            dateArray = dateValue
              .substring(0, dateValue.indexOf('T'))
              .split('-');
          } else if (dateValue.indexOf(' ') > -1) {
            dateArray = dateValue
              .substring(0, dateValue.indexOf(' '))
              .split('-');
          } else {
            dateArray = dateValue.split('-');
          }

          //decrement month because month starts at 0
          dateArray[1]--;
          date = new Date(...dateArray);
        } else {
          date = dateValue;
        }

        if (date == 'Invalid Date') {
          return null;
        }

        var formattedDate;

        switch (format) {
          case 'M/D/YYYY':
            formattedDate =
              date.getMonth() +
              1 +
              '/' +
              date.getDate() +
              '/' +
              date.getFullYear();
            break;
          case 'D/M/YYYY':
            formattedDate =
              date.getDate() +
              '/' +
              (date.getMonth() + 1) +
              '/' +
              date.getFullYear();
            break;
          case 'MM/DD/YYYY':
            formattedDate =
              this.addLeadingZero(date.getMonth() + 1) +
              '/' +
              this.addLeadingZero(date.getDate()) +
              '/' +
              date.getFullYear();
            break;
          case 'DD/MM/YYYY':
            formattedDate =
              this.addLeadingZero(date.getDate()) +
              '/' +
              this.addLeadingZero(date.getMonth() + 1) +
              '/' +
              date.getFullYear();
            break;
          case 'YYYY-MM-DD':
            formattedDate =
              date.getFullYear() +
              '-' +
              this.addLeadingZero(date.getMonth() + 1) +
              '-' +
              this.addLeadingZero(date.getDate());
            break;
          default:
            formattedDate = '';
        }

        return formattedDate;
      } else {
        return null;
      }
    },
    // big assumption: dateString stored as GMT in database but actually represent the user's timezone (whatever that may be)
    // for example, user schedules an event for 9 am local time, server saves it as 9 am GMT
    // this function converts the 9 am GMT to 9 am local time
    // I don't know how to update the database times to each user's local timezone without knowing what each user's timezone is...
    isDateBeforeToday: function (dateString) {
      if (dateString) {
        var today = new Date();
        var date = new Date(dateString.slice(0, -5)); // chop off .000Z (gmt timezone)
        return date < today;
      }

      return false;
    },

    addLeadingZero(number) {
      return number < 10 ? '0' + number : number;
    },
  },
};

export default dateTimeHelpers;
