<template>
  <!--v-container class="px-12"-->
  <v-container style="width: 90%">
    <slot />
  </v-container>
</template>
<script>
  export default {
    name: 'MainContainer',
    props: {},
    data() {
      return {};
    },
  };
</script>
<style></style>
