<!-- ----------------------------------------------------------------------- -->
<!--
name    : INNER WORK ORDER LIST

type    : component

used by : JobView.vue (only used there!)

uses    : work-order-create-dialog,
          table-status

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="inner-work-order-list">
    <v-container>
      <v-toolbar flat color="transparent" class="my-3">
        <v-toolbar-title>
          <span class="headline"> Work Orders </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title class="py-2">
          <v-btn
            v-if="allowAddInvoice"
            class="px-2 mx-3"
            color="button-primary"
            @click="invoiceDialog = true">
            Create Invoice
          </v-btn>
          <v-btn
            v-if="allowAddWorkOrder"
            color="button-primary"
            @click="addWorkOrder()">
            Create Work Order
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <div v-if="workorders" class="work-order-table">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="workorders"
          item-key="uuid"
          class="elevation-1"
          sort-by="name">
          <template v-slot:item="props">
            <tr @click="viewWorkOrder(props.item)">
              <td>{{ formatWorkOrderId(props.item.id) }}</td>
              <td>{{ props.item.name || 'Unassigned' }}</td>
              <td>
                <div class="date-container">
                  {{
                    props.item.scheduled_date
                      ? displayDateFormat(props.item.scheduled_date, dateFormat)
                      : 'Not Scheduled'
                  }}
                  <v-icon
                    v-if="isOverdue(props.item)"
                    class="overdue-icon"
                    title="Overdue"
                    >mdi-alert</v-icon
                  >
                </div>
              </td>
              <td>
                {{
                  props.item.user && props.item.user.name
                    ? props.item.user.name
                    : 'Not Assigned'
                }}
              </td>
              <td>{{ formatInvoiceId(props.item.invoice_number) }}</td>
              <td class="text-center">
                <table-status
                  :displayedStatus="props.item.status"
                  :status="'workorder ' + props.item.status" />
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            There are no work orders for this job, approve an estimate to create
            one.
          </template>
        </v-data-table>
      </div>
    </v-container>

    <!-- .................................................................... -->
    <!-- .................. WORK ORDER CREATE DIALOG ........................ -->
    <!-- .................................................................... -->
    <work-order-create-dialog
      :workorder="newWorkOrder"
      v-model="createDialog"
      @save="refreshWorkOrders" />

    <v-dialog v-model="moveDialog" width="500">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar color="info" flat dark>
          <v-toolbar-title> Select Work Tasks </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-model="selected_worktasks"
            :headers="worktaskheaders"
            :items="worktasks"
            class="elevation-1"
            show-select>
            <!-- eslint-disable vue/valid-v-slot vue/no-unused-vars -->
            <template v-slot:item.links="{item}">
              <a @click="moveWorkTasks()">Add Selected Work Tasks</a>
              <span>|</span>
            </template>
            <!-- eslint-enable -->
            <template v-slot:no-data>
              There are no work tasks in this job.
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button-primary" text @click="moveTasks()">Move</v-btn>
          <v-btn class="button-secondary" text @click="moveDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000">
      Your Changes have been saved
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>

    <simple-dialog-template
      :open="notifyDialog"
      dialogTitle="Select Work Tasks"
      :dialogText="notifyDialogText"
      dialogButtonOne="OK"
      @buttonOne="notifyDialog = false" />

    <progress-dialog v-model="busy" header="Processing" body="Processing">
    </progress-dialog>

    <v-dialog v-model="invoiceDialog" width="500">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar color="info" flat dark>
          <v-toolbar-title> Select Work Orders </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-for="(item, index) in workorders" v-bind:key="index">
            <v-checkbox
              v-if="item.status.toLowerCase() == 'completed'"
              color="primary"
              v-model="selected"
              :label="item.name"
              :value="item" />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button-primary" text @click="doBuildInvoice"
            >Create</v-btn
          >
          <v-btn class="button-secondary" text @click="invoiceDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-dialog
      v-model="errorDialog"
      :headline="errorHeader"
      :body="errorBody">
    </error-dialog>
  </div>
</template>

<script>
  // components
  import TableStatus from '@/components/table-status';
  import WorkOrderCreateDialog from '@/components/workorders/work-order-create-dialog';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import ErrorDialog from '@/components/helpers/ErrorDialog';
  import ProgressDialog from '@/components/helpers/ProgressDialog';

  // mixins
  import Localization from '@/mixins/localization';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import configurations from '@/mixins/configurations';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Invoices from '@/services/Invoices.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'InnerWorkOrderList',
    components: {
      'work-order-create-dialog': WorkOrderCreateDialog,
      'simple-dialog-template': SimpleDialogTemplate,
      'error-dialog': ErrorDialog,
      'table-status': TableStatus,
      'progress-dialog': ProgressDialog,
    },
    mixins: [dateTimeHelpers, Localization, configurations],
    props: {
      client: {
        type: Object,
        default: () => {},
      },
      client_uuid: {
        default: '',
        type: String,
      },
      estimate: {
        default: () => {},
        type: Object,
      },
      job: {
        type: Object,
        default: () => {},
      },
      job_uuid: {
        default: '',
        type: String,
      },
      job_site_uuid: {
        default: '',
        type: String,
      },
      tenant_uuid: {
        default: null,
        type: String,
      },
      workorders: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        errorDialog: false,
        errorHeader: '',
        errorBody: '',
        selected: [],
        busy: false,
        snackbar: false,
        invoiceDialog: false,
        invoice: {},
        notifyDialog: false,
        notifyDialogText: '',
        selected_worktasks: [],
        createDialog: false,
        viewDialog: false,
        editDialog: false,
        moveDialog: false,
        newWorkOrder: {},
        worktasks: [],
        headers: [
          {
            text: 'Work Order ID',
            value: 'id',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Scheduled Date',
            value: 'scheduled_date',
          },
          {
            text: 'Assigned To',
          },
          {
            text: 'Invoice ID',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        worktaskheaders: [
          {
            text: 'Category',
            value: 'workcategory',
          },
          {
            text: 'Type',
            value: 'worktype',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],
      };
    },
    computed: {
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      allowAddInvoice: function () {
        for (let i = 0; i < this.workorders.length; i++) {
          if (this.workorders[i].status.toLowerCase() == 'completed') {
            return true;
          }
        }
        return false;
      },
      allowAddWorkOrder: function () {
        for (let i = 0; i < this.workorders.length; i++) {
          if (
            this.workorders[i].status.toLowerCase() != 'completed' &&
            this.workorders[i].status.toLowerCase() != 'closed' &&
            this.workorders[i].status.toLowerCase() != 'invoiced'
          ) {
            return true;
          }
        }
        return false;
      },
    },
    async created() {},
    async mounted() {
      //
      this.createWorkOrderList();
    },
    methods: {
      uploadClientToQuickbooks: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create new or sync existing quickbooks client
        const resp = await Tenants.createQuickbooksClient(
          this.client,
          accessToken
        );

        if (resp && resp.quickbooks_id) {
          return resp.quickbooks_id;
        } else {
          return null;
        }
      },
      formatWorkOrderId(id) {
        return id ? 'WO-' + id.toString().padStart(5, '0') : '-';
      },
      formatInvoiceId(id) {
        return id ? 'INV-' + id.toString().padStart(5, '0') : '-';
      },
      isOverdue(workorder) {
        return (
          this.isDateBeforeToday(workorder.scheduled_date) &&
          workorder.status.toLowerCase() == 'scheduled'
        );
      },
      async getScheduledDateForWorkOrders() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        for (let i = 0; i < this.workorders.length; i++) {
          if (this.workorders[i].event_uuids) {
            // eslint-disable-next-line vue/no-mutating-props
            this.workorders[i].events = await Jobs.loadWorkOrderEventsByUUID(
              this.workorders[i].event_uuids,
              accessToken
            );
          }

          if (
            this.workorders[i].events &&
            this.workorders[i].events.length > 0
          ) {
            // eslint-disable-next-line vue/no-mutating-props
            this.workorders[i].scheduled_date =
              this.workorders[i].events[0].start_time;
          } else {
            // eslint-disable-next-line vue/no-mutating-props
            this.workorders[i].scheduled_date = '';
          }
        }
      },
      async getScheduledDateForSingleWorkOrder(workorder) {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        if (workorder.event_uuids) {
          // console.log( "event_uuids:", this.workorders[ i ].event_uuids )
          workorder.events = await Jobs.loadWorkOrderEventsByUUID(
            workorder.event_uuids,
            accessToken
          );
        }

        if (workorder.events && workorder.events.length > 0) {
          workorder.scheduled_date = workorder.events[0].start_time;
        } else {
          workorder.scheduled_date = '';
        }
      },

      addWorkOrder: function () {
        this.newWorkOrder = this.duplicateWorkOrder();
        this.newWorkOrder.notes = this.estimate.notes;
        this.createDialog = true;
      },
      refreshWorkOrders() {
        this.newWorkOrder = {};
        this.$emit('update-work-orders');
      },
      removeWorkOrder: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        for (let i = 0; i < this.selected.length; i++) {
          let filter = {
            workorder_uuid: this.selected[i].uuid,
            tenant_uuid: this.tenant_uuid
              ? this.tenant_uuid
              : this.$auth.userProfile.tenant_uuid,
          };

          let res_work_tasks = await Jobs.getWorkTasks(filter, accessToken);
          if (res_work_tasks) {
            // assign to global
            this.worktasks = res_work_tasks;

            if (res_work_tasks.length === 0) {
              //only allow deleting work orders with zero work tasks
              let res_confirmation = await Jobs.deleteWorkOrder(
                this.selected[i].uuid,
                accessToken
              );

              if (res_confirmation) {
                this.notifyDialogText = 'Empty work orders have been deleted.';
                this.notifyDialog = true;
              } else {
                console.log('could not delete empty work order');
              }
            }
          }
        }
      },
      doBuildInvoice: async function () {
        this.invoiceDialog = false;
        this.busy = true;

        console.log('job', this.job);
        console.log('selected', this.selected);

        await this.buildInvoice(this.selected);
        this.busy = false;
        this.$router.push({
          name: 'InvoiceView',
          params: {
            uuid: this.invoice.uuid,
          },
        });
      },
      addWorkType: function () {
        this.workType = {};
        this.createDialog = true;
      },
      viewWorkType: function (item) {
        this.workType = this.workTypes.indexOf(item);
        this.viewDialog = true;
      },
      editWorkType: function (item) {
        this.workType = this.workTypes.indexOf(item);
        this.editDialog = true;
      },
      savedEvent: function () {
        this.editDialog = false;
        this.viewDialog = false;
        this.createDialog = false;
        this.loadWorkTypes();
      },
      cancelEvent: function () {
        this.editDialog = false;
        this.viewDialog = false;
        this.createDialog = false;
      },
      viewWorkOrder: function (workorder) {
        this.$router.push({
          name: 'WorkOrderView',
          params: {
            uuid: workorder.uuid,
          },
        });
      },
      createWorkOrderList: async function () {
        this.$events.$emit('update-work-orders');
        //*************************************
        //this.getScheduledDateForWorkOrders();
        //this.getInvoiceIDs();
        //*************************************

        // // console.log( this.tenant_uuid, this.client_uuid, this.job_uuid )
        //
        // // Get the access token from the auth wrapper
        // const accessToken = await this.$auth.getTokenSilently();
        //
        // // add to filter for work orders
        // let job_uuid = this.job_uuid || ( this.job && this.job.uuid ? this.job.uuid : undefined );
        //
        // // get all work orders
        //
        // if ( job_uuid ) {
        //   let res_workorders = await Jobs.getAllWorkOrders( {
        //     job_uuid: job_uuid
        //   }, accessToken );
        //   // set global value if valid
        //   if ( res_workorders ) {
        //     // console.log( res_workorders );
        //     this.workorders = res_workorders;
        //
        //
        //
        //     // notify the header-view that we reloaded work orders
        //     this.$events.$emit( "workOrdersChanged", this.workorders )
        //
        //     // load addtional info about work orders

        //   } else {
        //     console.log( "could not get workorders" )
        //     return;
        //   }
        //
        // } else {
        //   console.log( "could not get job uuid to get workorders" )
        //   return;
        // }
      },
      async getInvoiceIDs() {
        const accessToken = await this.$auth.getTokenSilently();
        for (let i = 0; i < this.workorders.length; i++) {
          if (this.workorders[i].invoice_uuid) {
            let res_invoices = await Invoices.getInvoice(
              this.workorders[i].invoice_uuid,
              accessToken
            );
            if (res_invoices) {
              // eslint-disable-next-line vue/no-mutating-props
              this.workorders[i].invoice_number = res_invoices.number;
            }
          }
        }
      },
      // creates a workorder with the same info as the existing workorder
      duplicateWorkOrder() {
        let blankWorkOrder = Jobs.blankWorkOrder();

        blankWorkOrder.tenant_uuid = this.tenant_uuid;
        blankWorkOrder.client_uuid = this.client_uuid;
        blankWorkOrder.estimate_uuid = this.estimate_uuid;
        blankWorkOrder.job_site_uuid = this.job_site_uuid;
        blankWorkOrder.job_uuid = this.job_uuid;
        blankWorkOrder.status = 'No Work Tasks';

        return blankWorkOrder;
      },
      async initiateInvoice(invoiceParams) {
        this.invoice = Invoices.blankInvoice();

        // assign customer quickbooks id to new invoice or
        // upload client to quickbooks (for cases where quickbooks is enabled
        // but an invoice is being made for a customer that has yet to be synced up)
        if (
          this.configuration &&
          this.configuration.quickbooks &&
          this.$auth.tenantProfile.quickbooks_enabled
        ) {
          if (this.client.quickbooks_id) {
            this.invoice.client_quickbooks_id = this.client.quickbooks_id;
          } else {
            this.invoice.client_quickbooks_id =
              await this.uploadClientToQuickbooks();
          }
        }

        this.invoice.tenant_uuid = invoiceParams.tenant_uuid;
        this.invoice.client_uuid = invoiceParams.client_uuid;
        this.invoice.job_uuid = invoiceParams.job_uuid;
        this.invoice.client_name = this.client.client_name;
        this.invoice.client_address = this.client.address;
        this.invoice.client_city = this.client.city;
        this.invoice.client_state = this.client.state;
        this.invoice.client_postal_code = this.client.zip_code;
        this.invoice.client_quickbooks_id =
          this.configuration && this.configuration.quickbooks
            ? this.client.quickbooks_id
            : null;
        this.invoice.tenant_name = this.$auth.tenantProfile.invoice_name;
        this.invoice.tenant_address = this.$auth.tenantProfile.invoice_address;
        this.invoice.tenant_city = this.$auth.tenantProfile.invoice_city;
        this.invoice.tenant_state = this.$auth.tenantProfile.invoice_state;
        this.invoice.tenant_postal_code =
          this.$auth.tenantProfile.invoice_postal_code;
        this.invoice.tenant_phone = this.$auth.tenantProfile.contact_phone;
        this.invoice.terms = this.$auth.tenantProfile.invoice_terms;
        this.invoice.notes = this.$auth.tenantProfile.invoice_notes;
        this.invoice.tax_rate = this.$auth.tenantProfile.tax_rate;
        this.invoice.status = 'open';
        let issue_date = new Date();
        this.invoice.issue_date = issue_date;
        return this.invoice;
      },
      async buildInvoice(workorders) {
        //make sure at least one workorder was selected
        if (workorders.length == 0) {
          return Promise.reject({
            errorType: 'validation',
            errorMessage:
              'You must select at least one completed workorder to create an invoice.',
          });
        }

        let invoiceParams = {
          tenant_uuid: this.tenant_uuid || workorders[0].tenant_uuid,
          client_uuid: this.client_uuid || workorders[0].client_uuid,
          job_uuid: this.job_uuid || workorders[0].job_uuid,
        };

        const accessToken = await this.$auth.getTokenSilently();

        //make sure all selected workorders are completed
        for (let i = 0; i < workorders.length; i++) {
          if (workorders[i].status != 'Completed') {
            this.busy = false;
            return Promise.reject({
              errorType: 'validation',
              errorMessage: 'Only completed workorders may be invoiced.',
            });
          }
        }

        //sets up this.invoice
        await this.initiateInvoice(invoiceParams);

        let invoice_items = [];

        // pull work tasks for all workorders selected
        for (let i = 0; i < workorders.length; i++) {
          //load all completed work tasks for this workorder.
          let queryParams = {
            workorder_uuid: workorders[i].uuid,
            status: 'complete',
          };

          let worktasks = await Jobs.getWorkTasks(queryParams, accessToken);

          for (let w = 0; w < worktasks.length; w++) {
            // create invoice items for all work tasks pulled
            let amount = this.getWorkTaskTotal(worktasks[w]);
            let description =
              worktasks[w].workcategory +
              ' ' +
              worktasks[w].worktype +
              ' ' +
              worktasks[w].notes;
            let invoice_item = Invoices.blankInvoiceItem();

            invoice_item.description = description;
            invoice_item.amount = amount;
            invoice_item.tenant_uuid = this.invoice.tenant_uuid;
            invoice_item.client_uuid = this.invoice.client_uuid;
            invoice_item.job_uuid = this.invoice.job_uuid;
            invoice_item.invoice_uuid = this.invoice.uuid;

            invoice_items.push(invoice_item);
          }
        }

        let invoiceRes = await Invoices.createInvoice(
          this.invoice,
          accessToken
        );

        if (invoiceRes) {
          let invoiceItemRes = await Invoices.bulkCreateInvoiceItems(
            {
              invoice_items: invoice_items,
              tenant_uuid: this.invoice.tenant_uuid,
              client_uuid: this.invoice.client_uuid,
              invoice_uuid: this.invoice.uuid,
              quickbooks_id:
                this.configuration && this.configuration.quickbooks
                  ? this.client.quickbooks_id
                  : null,
              client: {
                address: this.invoice.client_address,
                city: this.invoice.client_city,
                state: this.invoice.client_state,
                zip_code: this.invoice.client_postal_code,
              },
            },
            accessToken
          );

          if (invoiceItemRes) {
            //Link and close the associated workorders
            for (let i = 0; i < workorders.length; i++) {
              //close workorder
              let workorder = workorders[i];
              workorder.invoice_uuid = this.invoice.uuid;
              workorder.status = 'Invoiced';

              // update work order through api
              await Jobs.updateWorkOrder(
                workorder.uuid,
                workorder,
                accessToken
              );
            }

            await Jobs.updateJobPhase(
              invoiceParams.job_uuid,
              'Invoice'
              // unsecured route
            );
          }
        }
      },
      getWorkTaskSubTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        let subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }
        return subtotal;
      },
      getWorkTaskTotal(entry) {
        let total = 0;
        total = this.getWorkTaskSubTotal(entry) - entry.discount;
        return total;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }

  .date-container {
    width: 110px;
  }

  .overdue-icon {
    float: right;
    font-size: 21px !important;
  }
</style>
