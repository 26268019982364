// src/services/Clients.service.js

import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  newTreeUUID() {
    return uuid.v4();
  },
  newJobSiteUUID() {
    return uuid.v4();
  },
  newClientUUID() {
    return uuid.v4();
  },

  blankClient() {
    return {
      uuid: uuid.v4(),
      number: 0,
      client_name: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      job_site_name: '',
      job_site_address: '',
      job_site_city: '',
      job_site_state: '',
      job_site_zip_code: '',
      job_site_country: '',
      client_type: '',
      client_status: 'Active',
      tenant_url: '',
    };
  },

  blankJobSite() {
    return {
      uuid: uuid.v4(),
      name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      client_uuid: '',
      tenant_uuid: '',
    };
  },

  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                    GET BY UUID
  // *****************************************************/

  // [ CLIENTS ]
  async getClient(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: `/service_clients/clients/${uuid}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ CLIENTS ]
  // NOTE: UNSECURED! USED FOR PUBLIC ESTIMATE APPROVAL LINK
  async getClientofProposal(uuid) {
    let res = await axios({
      method: 'GET',
      url: `/service_clients/clients/link/${uuid}`,
    });
    return res.data;
  },

  // [ TREES ]
  async getTree(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/trees/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ JOB SITES]
  async getJobSite(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/job_sites/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ JOB SITES]
  // NOTE: UNSECURED! USED FOR PUBLIC ESTIMATE APPROVAL LINK
  async getJobSiteForEstimate(uuid) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/job_sites/for_estimate/' + uuid,
    });
    return res.data;
  },

  // [ TREES ]
  // NOTE: UNSECURED! USED FOR PUBLIC ESTIMATE APPROVAL LINK
  async getTreesByJobSite(job_site_uuid) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/trees/by_job_site/' + job_site_uuid,
    });
    return res.data;
  },

  // [ CLIENTS ]
  async getClientName(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/clients/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res && res.data && res.data.client_name
      ? res.data.client_name
      : null;
  },

  // *****************************************************/
  //                    UPDATE
  // *****************************************************/

  async updateClient(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_clients/clients/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ TREES ]
  async updateTree(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_clients/trees/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ TREES ]
  async deleteTree(uuid, accessToken) {
    let res = await axios({
      method: 'DELETE',
      url: '/service_clients/trees/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ TREES ]
  async updateTreeNotes(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_clients/trees/notes/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ TREES ]
  async deleteTreePhoto(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_clients/trees/deletePhoto/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async updateJobSite(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_clients/job_sites/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  async createClient(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_clients/clients/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async createJobSite(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_clients/job_sites/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return data, not boolean
  },

  async createJobSitesBulk(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_clients/job_sites/bulk_import',
      data: data,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return data, not boolean
  },

  // [ TREES ]
  async createTree(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_clients/trees/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return data, not boolean
  },

  // [ TREES ]
  async uploadTreePhoto(tree_uuid, file, accessToken) {
    var data = new FormData();

    // Add the File object to the formdata
    data.append('file', file);
    data.append('photoId', uuid.v4());
    data.append('treeId', tree_uuid);
    console.log(data, file, tree_uuid);

    let res = await axios({
      method: 'POST',
      url: '/service_clients/trees/uploadTreePhoto',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return data, not boolean
  },

  // *****************************************************/
  //                    GET BY PARAMS
  // *****************************************************/
  async getClients(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/clients/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async checkIfClientEmailIsTaken(params, accessToken) {
    const response = await axios({
      method: 'GET',
      url: '/service_clients/clients/email_dup_check',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  // [ TREES ]
  async getTrees(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/trees/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ JOBSITES ]
  async getJobSites(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_clients/job_sites/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getClientsByArray(uuid, client_uuids, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_clients/clients/getClientsByArray',
      data: {
        tenant_uuid: uuid,
        clientIds: client_uuids,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  },
};
