var uppercase_us_states = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export default {
  mixins: [],
  data() {
    return {
      us_state_abbr: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
        'DC',
        'MH',
      ],
      us_states: uppercase_us_states,
      condition_values: ['Poor', 'Fair', 'Good', 'Excellent'],
      validate_required: [(v) => !!v || 'This is required'],
      validate_tos_accept: [
        (v) => !!v || 'Accepting the Terms of Service is required to proceed',
      ],
      validate_tenant_name: [(v) => !!v || 'Company Name is required'],
      validate_client_name: [(v) => !!v || 'Client Name is required'],
      validate_contact_name: [(v) => !!v || 'Contact Name is required'],
      validate_name: [(v) => !!v || 'Contact Name is required'],
      validate_tenant_url: [
        (v) => !!v || 'Tenant URL is required',
        (v) =>
          this.validateURL(v) ||
          'Cannot use spaces or special characters besides hyphen and underscore',
      ],
      newTenantURLRules: {
        required: (value) => !!value || 'Required.',
        match: (v) =>
          /^[a-zA-Z0-9\-_]*$/.test(v) ||
          'Cannot use spaces or special characters besides hyphen and underscore',
      },
      validate_address: [(v) => !!v || 'Address is required'],
      validate_city: [(v) => !!v || 'City is required'],
      validate_state: [
        (v) => !!v || 'State is required',
        (v) => (v && v.length >= 2) || 'Min 2 characters',
        // note: no match restrictions since we need to add in functionality for other countries
      ],
      validate_country: [(v) => !!v || 'Country is required'],
      validate_role: [(v) => !!v || 'Role is required'],
      validate_zip_code: [
        (v) => !!v || 'Postal Code is required',
        // v => (this.validateZip(v)) || 'Must be a valid zip code'
      ],
      validate_account_status: [(v) => !!v || 'Account Status is required'],
      validate_contact_phone: [
        (v) => !!v || 'Contact Phone is required',
        (v) => this.validatePhone(v) || 'Must be a valid phone number',
      ],
      validate_user_phone: [
        (v) =>
          this.validatePhone(v) || v == '' || 'Must be a valid phone number',
      ],
      validate_contact_email: [
        (v) => !!v || 'Contact email address is required',
        (v) => this.validateEmail(v) || 'Must be a valid email address',
      ],
      validate_user_email: [
        (v) => !!v || 'Email address is required',
        (v) => this.validateEmail(v) || 'Must be a valid email address',
      ],
      validate_subscription_start_date: [
        (v) => !!v || 'Subscription Start Date is required',
      ],
      validate_subscription_end_date: [
        (v) => !!v || 'Subscription End Date is required',
      ],
      validate_subscription_type: [
        (v) => !!v || 'Subscription Type is required',
      ],
      validate_client_type: [(v) => !!v || 'Client Type is required'],
      validate_client_status: [(v) => !!v || 'Client Status is required'],
      validate_special_instructions: [
        (v) => !!v || 'Special Instructions is required',
      ],
      validate_service_request_status: [(v) => !!v || 'Status is required'],
      //These are separate to allow for easier customization, ie max value, in the future
      validate_dbh: [
        (v) => this.validate_number(v) || 'Enter a valid DBH',
        (v) => !v || v > 0 || 'Enter a positive DBH',
      ],
      validate_height: [
        (v) => this.validate_number(v) || 'Enter a valid height',
        (v) => !v || v > 0 || 'Enter a positive height',
      ],
      validate_money_amount: [
        (v) => this.validate_number(v) || 'Enter a valid amount',
        (v) => !!v || 'This is required',
        (v) => !v || v > 0 || 'Enter a positive amount',
      ],
      validate_money_amount_0: [
        (v) => this.validate_number(v) || 'Enter a valid amount',
        (v) => !!v || 'This is required',
        (v) => !v || v >= 0 || 'Enter a positive amount',
      ],
      validate_discount: [(v) => !v || v >= 0 || 'Enter a positive amount'],
      validate_positive_number: [
        (v) => this.validate_number(v) || 'Enter a valid number',
        (v) => !v || v > 0 || 'Enter a positive number',
      ],
      validate_percentage: [
        (v) => this.validate_number(v) || 'Enter a valid number',
        (v) => !v || v >= 0 || 'Enter a non-negative number',
      ],

      validate_password: [
        (v) =>
          this.validatePasswordLength(v) ||
          'Password must be at least 8 characters long',
        (v) =>
          this.validatePasswordLower(v) ||
          'Password must contain at least one lower case character',
        (v) =>
          this.validatePasswordUpper(v) ||
          'Password must contain at least one upper case character',
        (v) =>
          this.validatePasswordNumber(v) ||
          'Password must containt at least one number',
      ],
      validate_work_order_name: [
        (v) => !!v || 'Name is required',
        (v) =>
          this.validateUniqueWorkOrderName(v) ||
          'A work order with that name already exists',
      ],
    };
  },
  methods: {
    validate_number(value) {
      /*if(value){
        var re = /^-?\d*(\.\d{0,2})?$/;
        return re.test(value);
      }else{
        return true;
      }*/
      return (
        !(typeof value == 'string' && !value) &&
        (!value || !Number.isNaN(value))
      );
    },
    validateEmail(value) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    },
    validatePhone(value) {
      //var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
      return re.test(value);
    },
    validateZip(value) {
      var re = /^[0-9]{5}(?:-[0-9]{4})?$/;
      return re.test(value);
    },
    validateURL(value) {
      var re = /^[a-zA-Z0-9\-_]*$/;
      return re.test(value);
    },
    validateURLBlacklist(value) {
      var blacklisted = false;
      var blockedURLs = ['planitgeo', 'www', 'login', 'admin', 'portal'];
      if (blockedURLs.includes(value.toLowerCase())) {
        blacklisted = true;
      }
      return !blacklisted;
    },
    validatePasswordLength(value) {
      var re = /^(?=.{8,100})/;
      return re.test(value);
    },
    validatePasswordLower(value) {
      var re = /^(?=.*[a-z])/;
      return re.test(value);
    },
    validatePasswordUpper(value) {
      var re = /^(?=.*[A-Z])/;
      return re.test(value);
    },
    validatePasswordNumber(value) {
      var re = /^(?=.*[0-9])/;
      return re.test(value);
    },
    formatMoney(options) {
      var prev = '';
      if (options.object && options.attribute) {
        prev = this[options.object][options.attribute];
        this[options.object][options.attribute] = prev
          ? parseFloat(prev).toFixed(2)
          : '0.00';
      } else {
        prev = this[options.object];
        this[options.object] = prev ? parseFloat(prev).toFixed(2) : '0.00';
      }
      if (options.key) {
        this[options.key] = prev;
      }
    },
    validateUniqueWorkOrderName(name) {
      if (!this.workorders || this.workorders.length == 0) {
        return true;
      }

      for (var workorder of this.workorders) {
        if (workorder.name == name) {
          return false;
        }
      }

      return true;
    },
  },
};
