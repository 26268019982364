import axios from 'axios';

const configurations = {
  mixins: [],
  props: {},
  data() {
    return {
      configuration: {},
    };
  },
  methods: {
    async loadConfiguration() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      //loads static configurations from the configurations endpoint. That's it.
      //returns the client_configuration object from the nonpublic service configuration file.
      const url = '/service_system/configurations/all';
      var response = await axios({
        method: 'GET',
        url: url,
        headers: {Authorization: `Bearer ${accessToken}`},
      });

      if (response) {
        this.configuration = response.data;
        return true;
      } else {
        console.log('Error retrieving static configurations');
        this.configuration = null;
        return false;
      }
    },

    async setupNotificationsUpdatedHandler() {
      //TLC only create event listener if we have a tenant uuid key
      let tenantID = await this.$auth.userProfile.tenant_uuid;

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      //update quickbooks status
      // TODO: enable with quickbooks rollout
      // let qbStatus = await axios({
      //   method: 'GET',
      //   url: '/service_tenants/quickbooks/token_status/' + tenantID,
      //   headers: {Authorization: `Bearer ${accessToken}`},
      // });

      // TODO: enable with quickbooks rollout
      // this.quickbookStatus = qbStatus.data;

      //create notification event listener
      this.$events.$on('NotificationsUpdated', async () => {
        var notificationResults = await axios({
          method: 'GET',
          url:
            '/service_notifications/notifications/all?tenant_uuid=' + tenantID,
          headers: {Authorization: `Bearer ${accessToken}`},
        });

        if (notificationResults && notificationResults.data) {
          // reverse sort notifications by created
          this.notifications = notificationResults.data
            .sort((x, y) => {
              return Date.parse(y.created) - Date.parse(x.created);
              //this generates the date from the created field in the format "1/25"
              // obj.created.split("T")[0].split("-").slice(1,3).map(x=>parseInt(x)).join("/")
            })
            .slice(0, 10);

          this.numberOfUnseenNotifications = 0;
          this.notifications.forEach((notification) => {
            if (!notification.beenSeen) {
              this.numberOfUnseenNotifications++;
            }
          });
        }
      });
    },
  },
  mounted() {},
  async created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
    //TODO how do we handle errors for this on the client side? It will log in the console
    //but might want to trigger some kind of action.
    await this.loadConfiguration();
  },
  watch: {},
  computed: {},
};

export default configurations;
