<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP JOB VIEW

type    : view

uses    : inner-work-order-list
          table-status
          header-view

route   : /jobs/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-job-view" v-if="componentCreated">
    <header-view
      title=""
      :id="
        'JOB-' +
        (job && job.number ? job.number.toString().padStart(5, '0') : 'UNKNOWN')
      "
      :status="jobPhase"
      status_title="Phase"
      :job_uuid="job.uuid"
      previous_page="/jobs" />
    <main-container>
      <v-row>
        <v-col cols="12" sm="12">
          <main-card>
            <v-row dense>
              <v-col cols="12" sm="12">
                <div class="title">Client</div>
              </v-col>
            </v-row>
            <v-row dense class="pb-3">
              <v-col cols="12" sm="6" md="6">
                <v-row dense>
                  <v-col
                    class="text-left text-sm-right"
                    cols="12"
                    sm="6"
                    md="6">
                    <b>Client Name</b>
                  </v-col>
                  <v-col>
                    <router-link :to="'/clients/view/' + client.uuid">
                      <div class="subheading">{{ client.client_name }}</div>
                    </router-link>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    class="text-left text-sm-right"
                    cols="12"
                    sm="6"
                    md="6">
                    <b>Contact Name</b>
                  </v-col>
                  <v-col>
                    {{ service_request.requestor_name }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    class="text-left text-sm-right"
                    cols="12"
                    sm="6"
                    md="6">
                    <b>Contact Phone</b>
                  </v-col>
                  <v-col>
                    {{ service_request.requestor_phone }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    class="text-left text-sm-right"
                    cols="12"
                    sm="6"
                    md="6">
                    <b>Contact Email</b>
                  </v-col>
                  <v-col>
                    {{ service_request.requestor_email }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row dense>
                  <v-col
                    class="text-left text-sm-right"
                    cols="12"
                    sm="6"
                    md="6">
                    <b>Billing Address</b>
                  </v-col>
                  <v-col>
                    <div>{{ service_request.address }}</div>
                    <div>
                      {{ service_request.city }}, {{ service_request.state }}
                      {{ service_request.zip_code }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </main-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-card min-height="192" class="pb-3 px-4">
            <v-row dense>
              <v-col cols="12" sm="12">
                <div class="title">Job Site</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>Name:</b>
              </v-col>
              <v-col>
                {{ jobSiteName }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>Address:</b>
              </v-col>
              <v-col>
                <div class="subheading">{{ job_site.address }}</div>
                <div class="subheading">
                  {{ job_site.city }}, {{ job_site.state }}
                  {{ job_site.zip_code }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card min-height="192" class="pb-3 px-4">
            <v-row dense>
              <v-col cols="12">
                <div class="title">Service Request</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>ID:</b>
              </v-col>
              <v-col>
                {{ serviceRequestNumber }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>Submitted On:</b>
              </v-col>
              <v-col>
                {{
                  displayDateFormat(
                    service_request.date_submitted,
                    dateFormat
                  ) || '-'
                }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>Approved On:</b>
              </v-col>
              <v-col>
                {{
                  displayDateFormat(
                    service_request.date_approved,
                    dateFormat
                  ) || '-'
                }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-center" cols="12" sm="12">
                <table-status
                  :status="service_request.service_request_status" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card min-height="192" class="pb-3 px-4" v-if="estimate">
            <v-row dense>
              <v-col cols="12" sm="6">
                <div class="title">Estimate</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>ID:</b>
              </v-col>
              <v-col>
                {{ estimateNumber }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>Created On:</b>
              </v-col>
              <v-col>
                {{
                  displayDateFormat(estimate.date_created, dateFormat) || '-'
                }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="6">
                <b>Approved On:</b>
              </v-col>
              <v-col>
                {{
                  displayDateFormat(estimate.approved_date, dateFormat) || '-'
                }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-center" cols="12" sm="12">
                <table-status :status="estimate.status" />
              </v-col>
            </v-row>
          </v-card>
          <v-card min-height="192" class="px-4" v-else>
            <v-row dense>
              <v-col cols="12" sm="6">
                <div class="title">Estimate</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center" cols="12" sm="12">
                There are no estimates for this job, approve a service request
                to create one.
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </main-container>

    <inner-work-order-list
      v-if="workorders"
      :workorders="workorders"
      :estimate="estimate"
      :client_uuid="job.client_uuid"
      :job_site_uuid="service_request.job_site_uuid"
      :tenant_uuid="job.tenant_uuid"
      :job_uuid="job.uuid"
      :job="job"
      :client="client"
      @update-work-orders="loadWorkOrders" />
    <v-container>
      <v-toolbar flat color="transparent" class="my-3">
        <span class="headline"> Invoices </span>
      </v-toolbar>
      <v-data-table
        :headers="invoicesHeaders"
        :items="invoices"
        class="elevation-1"
        sort-by="number">
        <template v-slot:item="props">
          <tr @click="viewInvoice(props.item)">
            <td>{{ formatInvoiceId(props.item.id) }}</td>
            <td>
              {{
                props.item.issue_date
                  ? displayDateFormat(props.item.issue_date, dateFormat)
                  : 'Not Available'
              }}
            </td>
            <td>{{ formatCurrency(props.item.amount, currencySymbol) }}</td>
            <td>{{ formatCurrency(props.item.balance, currencySymbol) }}</td>
            <td class="text-center">
              <table-status :status="props.item.status" />
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          There are no invoices for this job, close a work order to create one.
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
  // components
  import InnerWorkOrderList from '@/components/workorders/inner-work-order-list';
  import TableStatus from '@/components/table-status';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';

  // mixins
  import Localization from '@/mixins/localization';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';
  import Invoices from '@/services/Invoices.service.js';

  export default {
    name: 'AppJobView',
    components: {
      'inner-work-order-list': InnerWorkOrderList,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      'table-status': TableStatus,
    },
    mixins: [Localization, dateTimeHelpers],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        componentCreated: false,
        invoicesHeaders: [
          {
            text: 'Invoice ID',
            value: 'number',
          },
          {
            text: 'Date Created',
            value: 'issue_date',
          },
          {
            text: 'Amount',
            value: 'amount',
          },
          {
            text: 'Balance',
            value: 'balance',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        job: {},
        client: {},
        invoices: [],
        job_site: {},
        service_request: {},
        estimate: {},
        workorders: [],
      };
    },
    computed: {
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      estimateNumber() {
        return this.estimate && this.estimate.number
          ? 'EST-' + this.estimate.number.toString().padStart(5, '0')
          : '-';
      },
      serviceRequestNumber() {
        return this.service_request && this.service_request.number
          ? 'SR-' + this.service_request.number.toString().padStart(5, '0')
          : '-';
      },
      jobSiteName: function () {
        return this.job_site && this.job_site.name
          ? this.job_site.name
          : 'No Site Name';
      },
      jobPhase() {
        var phase = 'Service Request'; // job starts in SR phase

        if (this.service_request.service_request_status == 'Declined') {
          phase = 'Closed';
        }

        if (this.estimate && this.estimate.id) {
          phase = 'Estimate'; // if the job has an estimate, it is in estimate phase
        }

        // if there are work orders for that job, and if all are completed, then job is complete, otherwise it is in Work
        if (this.workorders && this.workorders.length > 0) {
          var allWorkOrdersClosed = true;
          this.workorders.forEach((order) => {
            if (order.status != 'Invoiced') {
              allWorkOrdersClosed = false;
            }
          });

          if (allWorkOrdersClosed) {
            phase = 'Closed';
          } else {
            phase = 'Work';
          }
        }

        return phase;
      },
    },
    watch: {
      // workorders() {
      //   console.log( "workorders watcher!", this.workorders )
      // }
    },
    async created() {
      if (!this.uuid) {
        console.log('empty uuid passed into jobview');
        return;
      }

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // Load job we are looking at
      this.job = await Jobs.getJob(this.uuid, accessToken);

      if (this.job) {
        // load workorders
        this.loadWorkOrders();

        // get client
        this.client = await Clients.getClient(
          this.job.client_uuid,
          accessToken
        );

        // get service request
        var res_service_request = await Jobs.getServiceRequestByJobUUID(
          this.uuid,
          accessToken
        );

        // validate, get job site if valid
        if (res_service_request) {
          this.service_request = res_service_request;

          // console.log( "service request: ", res_service_request );

          // get job site if possible
          if (res_service_request.job_site_uuid) {
            this.job_site = await Clients.getJobSite(
              res_service_request.job_site_uuid,
              accessToken
            );
          }
        }

        // load invoices
        //this.loadInvoices();

        // get estimate
        var res_estimate = await Jobs.getEstimateByJobUUID(
          this.uuid,
          accessToken
        );

        // validate, set globally
        if (res_estimate) {
          this.estimate = res_estimate;
        }

        if (this.service_request) {
          this.componentCreated = true;
        }
      } else {
        console.log("couldn't load job");
      }
    },
    mounted() {},
    methods: {
      formatInvoiceId(id) {
        return id ? 'INV-' + id.toString().padStart(5, '0') : '-';
      },
      viewInvoice(invoice) {
        this.$router.push('/invoices/view/' + invoice.uuid);
      },
      associateInvoices() {
        for (var workorder of this.workorders) {
          for (var invoice of this.invoices) {
            if (workorder.invoice_uuid === invoice.uuid) {
              workorder.invoice = invoice;
              workorder.invoice_number = invoice.number;
            }
          }
        }
      },
      // will load and reload workorders on change
      async loadWorkOrders() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // DB: this is part of the madness
        this.invoices = await Invoices.getInvoicesByTenant(
          this.job.tenant_uuid,
          {job_uuid: this.uuid},
          accessToken
        );

        // get all work orders for job
        this.workorders = await Jobs.getAllWorkOrders(
          {
            job_uuid: this.uuid,
          },
          accessToken
        );

        console.log('jobview workorders = ', this.workorders);

        // DB: some more madness
        for (var i = 0; i < this.workorders.length; i++) {
          // associate Invoices to Work Orders
          if (
            this.workorders[i].events &&
            this.workorders[i].events.length > 0
          ) {
            this.workorders[i].scheduled_date =
              this.workorders[i].events[0].start_time;
          }
        }
        // DB: this did not work...
        // the Invoice data is now loaded above (using the same token?!?)
        //this.loadInvoices();

        // DB: and a little bit more madness
        // associate Invoices to Work Orders... tis was being done in the loadInvocie()
        if (
          this.invoices.length > 0 &&
          this.workorders &&
          this.workorders.length > 0
        ) {
          //this.associateInvoices( this.invoices )
          for (var workorder of this.workorders) {
            for (var invoice of this.invoices) {
              if (workorder.invoice_uuid === invoice.uuid) {
                workorder.invoice = invoice;
                workorder.invoice_number = invoice.number;
              }
            }
          }
        }
      },
      // will load and reload invoices on change
      async loadInvoices() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // get invoices
        var invoices = await Invoices.getInvoicesByTenant(
          this.job.tenant_uuid,
          {job_uuid: this.uuid},
          accessToken
        );

        if (invoices) {
          this.invoices = invoices;
          if (
            this.invoices.length > 0 &&
            this.workorders &&
            this.workorders.length > 0
          ) {
            //this.associateInvoices( this.invoices )
            for (var workorder of this.workorders) {
              for (var invoice of this.invoices) {
                if (workorder.invoice_uuid === invoice.uuid) {
                  workorder.invoice = invoice;
                  workorder.invoice_number = invoice.number;
                }
              }
            }
          }
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
