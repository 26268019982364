export default {
  mixins: [],
  data() {
    return {
      // default structure
      localization: null,
      localizationPromise: null,
      countryItems: [
        {text: 'Australia', value: 'Australia'},
        {text: 'Canada', value: 'Canada'},
        {text: 'United Kingdom', value: 'United Kingdom'},
        {text: 'United States', value: 'United States'},
      ],
      mapDSettingsByCountry: {
        Australia: {
          center: {lat: -25.2744, lng: 133.7751},
          zoom: 4,
        },
        Canada: {
          center: {lat: 56.1304, lng: -106.3468},
          zoom: 3,
        },
        'United Kingdom': {
          center: {lat: 55.3781, lng: -3.436},
          zoom: 5,
        },
        'United States': {
          center: {lat: 39, lng: -98.7146},
          zoom: 4,
        },
      },
      currencySymbols: [
        {text: '$', value: '$'},
        {text: '£', value: '£'},
        {text: '€', value: '€'},
      ],
      dateFormats: [
        {text: 'M/D/YYYY', value: 'M/D/YYYY'}, // US
        {text: 'D/M/YYYY', value: 'D/M/YYYY'}, // AU, UK
        {text: 'MM/DD/YYYY', value: 'MM/DD/YYYY'}, // US
        {text: 'DD/MM/YYYY', value: 'DD/MM/YYYY'}, // AU, UK
        {text: 'YYYY-MM-DD', value: 'YYYY-MM-DD'}, // CA
      ],
      timeFormats: [
        {text: 'Standard Time (12 hr)', value: 'standard'},
        {text: 'Military Time (24 hr)', value: 'military'},
      ],
      unitOfMeasurementFormats: {
        dbh: {
          metric: 'cm',
          imperial: 'in',
        },
        height: {
          metric: 'm',
          imperial: 'ft',
        },
      },
      unitTypes: [
        {text: 'Metric', value: 'metric'},
        {text: 'Imperial', value: 'imperial'},
      ],
    };
  },

  // creation for logged in user / pages that have not loaded tenant information
  created() {},

  async mounted() {
    // wait for it to finish loading localization
    // await this.localizationPromise
    // await this.localization
    // if localization fails to load, use passed in variable
    // if (this.localization_settings && Object.keys(this.localization_settings).length > 0) {
    //   this.localization = this.localization_settings
    // }
  },
  methods: {
    formatCurrency(amount, currency_symbol) {
      if (amount === '0.00') {
        amount = 0;
      }

      if (amount != 0 && (!amount || !Number.parseFloat(amount))) {
        return '';
      }

      return currency_symbol + Number.parseFloat(amount).toFixed(2);
    },
    getUnits(unitType, units_of_measurement) {
      if (units_of_measurement) {
        var unitTypeSettings =
          this.unitOfMeasurementFormats[unitType.toLowerCase()];
        return unitTypeSettings ? unitTypeSettings[units_of_measurement] : '';
      }
    },
    formatUnit(unitType, amount, units_of_measurement) {
      if (!unitType || !amount || !units_of_measurement) {
        console.log(
          'localization format unit: ',
          unitType,
          amount,
          units_of_measurement
        );
        return;
      }
      switch (unitType.toLowerCase()) {
        case 'height':
          return this.formatHeight(amount, units_of_measurement);
        case 'dbh':
          return this.formatDBH(amount, units_of_measurement);
        default:
          return amount;
      }
    },
    formatDBH(dbh, units_of_measurement) {
      if (!dbh || !Number.parseFloat(dbh)) {
        return '';
      }

      return dbh + ' ' + this.getUnits('dbh', units_of_measurement);
    },
    formatHeight(height, units_of_measurement) {
      if (!height || !Number.parseFloat(height)) {
        return '';
      }

      return height + ' ' + this.getUnits('height', units_of_measurement);
    },
    // format the object 'unitOfMeasurementFormats' so it all the unit types for a single system
    getUnitsOfMeasurment(units_of_measurement) {
      var unitOfMeasurement = {};

      for (var measurement in this.unitOfMeasurementFormats) {
        unitOfMeasurement[measurement] = this.getUnits(
          measurement,
          units_of_measurement
        );
      }

      return unitOfMeasurement;
    },
    formatTime(date, time_format) {
      var time;

      switch (time_format) {
        case 'standard':
          var hour = date.getHours() % 12;
          hour = hour == 0 ? 12 : hour;
          time =
            hour +
            ':' +
            this.addLeadingZero(date.getMinutes()) +
            ' ' +
            (date.getHours() >= 12 ? 'PM' : 'AM');
          break;
        case 'military':
          time =
            this.addLeadingZero(date.getHours()) +
            '' +
            this.addLeadingZero(date.getMinutes());
          break;
        default:
          time = '';
      }

      return time;
    },

    addLeadingZero(number) {
      return number < 10 ? '0' + number : number;
    },
  },
  computed: {},
};
