<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ status }}
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="blue"
              indeterminate />
          </div>
        </v-card-text>
        <v-card-actions />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'ProgressDialog',
    mixins: [],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      status: {
        type: String,
        default: 'Processing',
      },
    },
    data() {
      return {
        dialog: false,
      };
    },
    computed: {},
    watch: {
      value: function () {
        this.dialog = this.value;
      },
    },
    created() {
      this.dialog = this.value;
    },
    mounted() {},
    methods: {},
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
