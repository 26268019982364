<!-- ----------------------------------------------------------------------- -->
<!--
name    : work order create dialog

type    : component

used by : inner-workorder-list
          work-task-list-workoder-view

uses    : none

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-order-create-dialog">
    <v-dialog v-model="opendialog" persistent width="500">
      <v-card color="grey lighten-4" min-width="350px" text>
        <v-form ref="form">
          <v-toolbar color="info" text dark>
            <v-toolbar-title>New Work Order</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <!-- eslint-disable vue/no-mutating-props -->
                  <v-text-field
                    ref="workOrderName"
                    v-model="workorder.name"
                    label="Name"
                    :rules="validate_work_order_name"
                    validate-on-blur
                    required />
                  <!-- eslint-enable -->
                </v-flex>
                <v-flex xs12>
                  <!-- eslint-disable vue/no-mutating-props -->
                  <v-text-field
                    v-model="workorder.notes"
                    clearable
                    label="Notes" />
                  <!-- eslint-enable -->
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="button-primary" text @click="save()"> Save </v-btn>
            <v-btn class="button-secondary" text @click="opendialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000">
      Record has been saved
      <v-btn class="button-primary" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  // mixins
  import forms from '@/mixins/forms';

  // services
  import Jobs from '@/services/Jobs.service.js';

  export default {
    name: 'WorkorderCreateDialog',
    mixins: [forms],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      workorder: {
        type: Object,
        default: () => {},
      },
      // included so forms.validate_work_order_name can validate no duplicate names
      workordersValidation: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        snackbar: false,
        opendialog: false,
      };
    },
    watch: {
      value() {
        this.opendialog = this.value;
      },
      opendialog() {
        this.$emit('input', this.opendialog);
      },
    },
    methods: {
      async save() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create new workorder
        await Jobs.createWorkOrder(this.workorder, accessToken);

        this.$emit('save');
        this.opendialog = false;
        this.snackbar = true;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  table {
    width: 100%;
  }
</style>
